























// Define the component in class-style
import Component from "vue-class-component";
import Vue from "vue";
import { Prop, VModel, Watch } from "vue-property-decorator";
import { value } from "@/models/CustomTypes";
import { PurchaseGroupApi } from "@/api/PurchaseGroupApi";
import { PurchaseGroup } from "@/context/purchase_groups/model/domain";

@Component
export default class PurchaseGroupAutocomplete extends Vue {
  @Prop({ default: "Grupa zakupowa" }) label: string;
  @Prop({ default: "" }) queryParam: string;
  @Prop({ default: false }) disabled: boolean;
  @Prop({ default: false }) returnObject: boolean;
  @Prop({ default: false }) multiple: boolean;
  @Prop({ default: "" }) hint: string;
  @Prop({ default: () => [] }) rules: [];
  @VModel({ type: [Number, Object] }) localValue: value;
  @Prop({ default: "" }) icon: number;

  items: PurchaseGroup[] = [];

  async mounted() {
    await this.loadData();
  }

  async loadData() {
    const items = await PurchaseGroupApi.autocomplete();
    this.items = items;
  }

  @Watch("queryParam")
  onQueryParamChange(newVal: value, oldVal: value) {
    if (newVal != oldVal) {
      this.loadData();
    }
  }
}
