var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.types,"outlined":"","dense":"","chips":"","small-chips":""},on:{"change":_vm.clearItems},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.years,"outlined":"","dense":"","chips":"","small-chips":""},on:{"change":_vm.clearItems},model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"float-right",attrs:{"color":"primary","text":""},on:{"click":_vm.loadItems}},[_vm._v(" Odśwież ")])],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"items-per-page":100,"loading":_vm.loading,"height":"60vh","fixed-header":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Dashboard zakupów")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{staticClass:"shrink mr-2",attrs:{"label":"Wyszukaj...","outlined":"","dense":"","prepend-icon":"mdi-magnify","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}})],1)]},proxy:true},{key:"item.M1",fn:function(ref){
var item = ref.item;
return [_c('span',{style:({ color: _vm.color(item.M1) })},[_vm._v(" "+_vm._s(item.M1)+" ")])]}},{key:"item.M2",fn:function(ref){
var item = ref.item;
return [_c('span',{style:({ color: _vm.color(item.M2) })},[_vm._v(" "+_vm._s(item.M2)+" ")])]}},{key:"item.M3",fn:function(ref){
var item = ref.item;
return [_c('span',{style:({ color: _vm.color(item.M3) })},[_vm._v(" "+_vm._s(item.M3)+" ")])]}},{key:"item.M4",fn:function(ref){
var item = ref.item;
return [_c('span',{style:({ color: _vm.color(item.M4) })},[_vm._v(" "+_vm._s(item.M4)+" ")])]}},{key:"item.M5",fn:function(ref){
var item = ref.item;
return [_c('span',{style:({ color: _vm.color(item.M5) })},[_vm._v(" "+_vm._s(item.M5)+" ")])]}},{key:"item.M6",fn:function(ref){
var item = ref.item;
return [_c('span',{style:({ color: _vm.color(item.M6) })},[_vm._v(" "+_vm._s(item.M6)+" ")])]}},{key:"item.M7",fn:function(ref){
var item = ref.item;
return [_c('span',{style:({ color: _vm.color(item.M7) })},[_vm._v(" "+_vm._s(item.M7)+" ")])]}},{key:"item.M8",fn:function(ref){
var item = ref.item;
return [_c('span',{style:({ color: _vm.color(item.M8) })},[_vm._v(" "+_vm._s(item.M8)+" ")])]}},{key:"item.M9",fn:function(ref){
var item = ref.item;
return [_c('span',{style:({ color: _vm.color(item.M9) })},[_vm._v(" "+_vm._s(item.M9)+" ")])]}},{key:"item.M10",fn:function(ref){
var item = ref.item;
return [_c('span',{style:({ color: _vm.color(item.M10) })},[_vm._v(" "+_vm._s(item.M10)+" ")])]}},{key:"item.M11",fn:function(ref){
var item = ref.item;
return [_c('span',{style:({ color: _vm.color(item.M11) })},[_vm._v(" "+_vm._s(item.M11)+" ")])]}},{key:"item.M12",fn:function(ref){
var item = ref.item;
return [_c('span',{style:({ color: _vm.color(item.M12) })},[_vm._v(" "+_vm._s(item.M12)+" ")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.loadItems}},[_vm._v(" Odśwież ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }