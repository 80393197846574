





























import Vue from "vue";
import LotCreationStatusModel from "@/context/wallets/models/LotCreationStatusModel";
import Component from "vue-class-component";
import {WalletApi} from "@/api/WalletApi";

@Component({
  components: {}
})
export default class LotCreationStatuses extends Vue {
  headers: Array<Record<string, unknown>> = [
    {
      text: "Portfel",
      value: "wallet",
      align: "center"
    },
    {
      text: "Index",
      value: "financialInstrument",
      align: "center"
    },
    {
      text: "Data stworzenia",
      value: "date",
      align: "center"
    },
    {
      text: "Status",
      value: "status",
      align: "center"
    }
  ]
  items: LotCreationStatusModel[] = []
  loading = false;

  async mounted(): Promise<void> {
    await this.loadItems();
  }

  async loadItems(): Promise<void> {
    this.loading = true;
    const response = await WalletApi.lotCreationStatusData();
    const items = response.data;
    this.items = [];
    this.items = this.items.concat(items);
    this.loading = false;
  }
}
