



























































































































































import Vue from "vue";
import Component from "vue-class-component";
import { RecommendationListModel } from "@/context/recommendations/models/recommendations/RecommendationListModel";
import { WalletApi } from "@/api/WalletApi";
import {
  getTypes,
  getYears,
  ListViewElementModel,
  PurchaseEntryModel
} from "@/context/wallets/models/PurchaseEntryModel";
import { isString } from "lodash";

@Component({
  components: {}
})
export default class WalletPurchaseDashboardContainer extends Vue {
  years = getYears();
  types = getTypes();
  year = this.years[0];
  type = this.types[0];
  loading = false;

  headers: Array<Record<string, unknown>> = [
    {
      text: "Portfel",
      value: "name",
      align: "center"
    },
    {
      text: "Styczeń",
      value: "M1",
      align: "center"
    },
    {
      text: "Luty",
      value: "M2",
      align: "center"
    },
    {
      text: "Marzec",
      value: "M3",
      align: "center"
    },
    {
      text: "Kwiecień",
      value: "M4",
      align: "center",
      sortable: false
    },
    {
      text: "Maj",
      value: "M5",
      align: "center",
      sortable: false
    },
    {
      text: "Czerwiec",
      value: "M6",
      align: "center",
      sortable: false
    },
    {
      text: "Lipiec",
      value: "M7",
      align: "center",
      sortable: false
    },
    {
      text: "Sierpień",
      value: "M8",
      align: "center",
      sortable: false
    },
    {
      text: "Wrzesień",
      value: "M9",
      align: "center",
      sortable: false
    },
    {
      text: "Październik",
      value: "M10",
      align: "center",
      sortable: false
    },
    {
      text: "Listopad",
      value: "M11",
      align: "center",
      sortable: false
    },
    {
      text: "Grudzień",
      value: "M12",
      align: "center",
      sortable: false
    }
  ];
  items: ListViewElementModel[] = [];
  search: string = "";
  async mounted() {
    await this.loadItems();
  }

  async loadItems() {
    this.loading = true;
    const response = await WalletApi.shoppingDashboard(this.type, this.year);
    this.items = [];
    this.items = PurchaseEntryModel.GetListOfValues(PurchaseEntryModel.CreatePurchaseEntryModelFromResponseData(response.data), this.type);
    console.log(this.items)
    this.loading = false;
  }
  clearItems() {
    this.items = [];
  }

  color(value) {
    if(isString(value)){
      return "#000000"
    } else if (value === 100) {
      return "#80D84A";
    } else if (value >= 50 && value <= 99) {
      return "#F2961C";
    } else {
      return "#F14668";
    }
  }
}
