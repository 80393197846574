
















import Component from "vue-class-component";
import StepperPageLayout from "@/components/layouts/StepperPageLayout.vue";
import AbstractFormPage from "@/components/forms/AbstractFormPage";
import DetailForm from "@/context/agreements/components/create-agreement/forms/DetailForm.vue";
import { AgreementFormModel } from "@/context/agreements/model/AgreementFormModel";
import VolumesForm from "@/context/agreements/components/create-agreement/forms/VolumesForm.vue";
import FinancialInstrumentsForm from "@/context/agreements/components/create-agreement/forms/FinancialInstrumentForm.vue";
import StepperContent from "@/context/agreements/components/create-agreement/StepperContent.vue";
import StepperHeader from "@/context/agreements/components/create-agreement/StepperHeader.vue";
import { AgreementApi } from "@/api/AgreementApi";
import { ToastOptions } from "vue-toasted";
@Component({
  components: {
    StepperContent,
    StepperHeader,
    FinancialInstrumentsForm,
    VolumesForm,
    DetailForm,
    StepperPageLayout
  }
})
export default class CreateAgreementContainer extends AbstractFormPage {
  step = 1;
  formModel: AgreementFormModel = new AgreementFormModel();
  async onSubmit() {
    try {
      await AgreementApi.create(this.formModel.toCommand());
      const options: ToastOptions = {
        type: "success",
        duration: 5 * 1000,
        position: "top-right"
      };
      const message = "Umowa została dodana.";
      this.$toasted.show(message, options);
      this.$router.push("/agreements/list");
    } catch (e) {
      const options: ToastOptions = {
        type: "error",
        icon: "mdi-alert-circle",
        duration: 5 * 1000,
        position: "top-right"
      };
      const message = "Wysłanie formularza spowodowało błąd.";
      this.$toasted.show(message, options);
    }
  }
}
