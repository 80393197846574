




































































import Vue from "vue";
import Component from "vue-class-component";
import { RecommendationListModel } from "@/context/recommendations/models/recommendations/RecommendationListModel";
import { WalletApi } from "@/api/WalletApi";

@Component({
  components: {}
})
export default class WalletPurchaseDashboardContainer extends Vue {
  years = [2023];
  types = ["ENERGY", "GAS", "GREEN_CERTIFICATE"];
  year = 2023;
  type = "ENERGY";
  loading = false;

  headers: Array<Record<string, unknown>> = [
    {
      text: "Portfel",
      value: "name",
      align: "center"
    },
    {
      text: "Niezakupiony wolumen",
      value: "volume_left",
      align: "center"
    },
    {
      text: "Końcowa data zakupu",
      value: "date",
      align: "center"
    },
    {
      text: "Akcje",
      value: "actions",
      align: "center"
    }
  ];
  items: RecommendationListModel[] = [];
  search: string = "";
  cl = console.log;

  async loadItems() {
    this.loading = true;
    const response = await WalletApi.volumeRecommendationData(this.type, this.year);
    const items = response.data;
    this.items = [];
    this.items = this.items.concat(items);
    this.loading = false;
  }

  async deleteItem(item) {
    this.loading = true;
    const response = await WalletApi.deleteVolumeRecommendation(item.id);
    await this.loadItems();
    this.loading = false;
  }
  clearItems() {
    this.items = [];
  }

  color(valueBought) {
    if (valueBought === 100) {
      return "#80D84A";
    } else if (valueBought >= 50 && valueBought <= 99) {
      return "#F2961C";
    } else {
      return "#F14668";
    }
  }
}
