export class AgreementListModel {
  id: number;
  client: string;
  seller: string;
  volume: number;
  media: string;

  constructor(id: number, client: string, seller: string, volume: number, media: string) {
    this.id = id;
    this.client = client;
    this.seller = seller;
    this.volume = volume;
    this.media = media;
  }

  public static deserialize(apiObj: any) {
    return new AgreementListModel(
      apiObj.id,
      apiObj.client,
      apiObj.seller,
      parseFloat(apiObj.volume),
      apiObj.media_type.name
    );
  }
}
