


















import Vue from "vue";
import Component from "vue-class-component";
import { PurchaseGroupApi } from "@/api/PurchaseGroupApi";
import PurchaseGroupForm from "@/context/purchase_groups/components/PurchaseGroupForm.vue";
import { VForm } from "@/models/CustomTypes";
@Component({
  components: { PurchaseGroupForm }
})
export default class CreatePurchaseGroupContainer extends Vue {
  async submit() {
    const form = this.$refs.groupForm as PurchaseGroupForm;
    if (form.validate()) {
      const pg = await PurchaseGroupApi.create(form.form);
      this.$router.push({ path: `/purchase_groups/show/${pg.id}` });
    }
  }
}
