export function getTypes(): string[] {
  return ["ENERGY", "GAS", "GREEN_CERTIFICATE"];
}

export function getYears(): number[]{
  const result = []
  for (let i = 2021; i < new Date().getFullYear() + 2; i++) {
    result.push(i);
  }
  return result;
}

function CreateTimeUnit(data: any): TimeUnit{
  return new TimeUnit(
    new Instrument(
      data.ENERGY.VOLUME_BOUGHT_PERCENT,
      data.ENERGY.VOLUME_BOUGHT,
      data.ENERGY.VOLUME_LEFT,
      data.ENERGY.VOLUME_LEFT_PERCENT
    ),
    new Instrument(
      data.GAS.VOLUME_BOUGHT_PERCENT,
      data.GAS.VOLUME_BOUGHT,
      data.GAS.VOLUME_LEFT,
      data.GAS.VOLUME_LEFT_PERCENT
    ),
    new Instrument(
      data.GREEN_CERTIFICATES.VOLUME_BOUGHT_PERCENT,
      data.GREEN_CERTIFICATES.VOLUME_BOUGHT,
      data.GREEN_CERTIFICATES.VOLUME_LEFT,
      data.GREEN_CERTIFICATES.VOLUME_LEFT_PERCENT
    )
  )
}

class Instrument {
  volume_bought?: number;
  volume_bought_percent: number;
  volume_left?: number;
  volume_left_percent?: number;

  constructor(
    volume_bought_percent: number,
    volume_bought?: number,
    volume_left_percent?: number,
    volume_left?: number
  ) {
    this.volume_bought = volume_bought;
    this.volume_bought_percent = volume_bought_percent;
    this.volume_left = volume_left;
    this.volume_left_percent = volume_left_percent;
  }


}

class TimeUnit {
  energy: Instrument;
  gas: Instrument;
  green_certificates: Instrument;

  constructor(energy: Instrument, gas: Instrument, green_certificates: Instrument) {
    this.energy = energy;
    this.gas = gas;
    this.green_certificates = green_certificates;
  }
}

export class ListViewElementModel{
  name: string;
  M1: number;
  M2: number;
  M3: number;
  M4: number;
  M5: number;
  M6: number;
  M7: number;
  M8: number;
  M9: number;
  M10: number;
  M11: number;
  M12: number;

  constructor(name: string, M1: number, M2: number, M3: number, M4: number, M5: number, M6: number, M7: number, M8: number, M9: number, M10: number, M11: number, M12: number) {
    this.name = name;
    this.M1 = M1;
    this.M2 = M2;
    this.M3 = M3;
    this.M4 = M4;
    this.M5 = M5;
    this.M6 = M6;
    this.M7 = M7;
    this.M8 = M8;
    this.M9 = M9;
    this.M10 = M10;
    this.M11 = M11;
    this.M12 = M12;
  }
}

export class PurchaseEntryModel {
  M1: TimeUnit;
  M2: TimeUnit;
  M3: TimeUnit;
  M4: TimeUnit;
  M5: TimeUnit;
  M6: TimeUnit;
  M7: TimeUnit;
  M8: TimeUnit;
  M9: TimeUnit;
  M10: TimeUnit;
  M11: TimeUnit;
  M12: TimeUnit;
  date: string;
  name: string;

  constructor(
    M1: TimeUnit,
    M2: TimeUnit,
    M3: TimeUnit,
    M4: TimeUnit,
    M5: TimeUnit,
    M6: TimeUnit,
    M7: TimeUnit,
    M8: TimeUnit,
    M9: TimeUnit,
    M10: TimeUnit,
    M11: TimeUnit,
    M12: TimeUnit,
    date: string,
    name: string
  ) {
    this.M1 = M1;
    this.M2 = M2;
    this.M3 = M3;
    this.M4 = M4;
    this.M5 = M5;
    this.M6 = M6;
    this.M7 = M7;
    this.M8 = M8;
    this.M9 = M9;
    this.M10 = M10;
    this.M11 = M11;
    this.M12 = M12;
    this.date = date;
    this.name = name;
  }

  static GetListOfValues(
    data:PurchaseEntryModel[],
    type:string
    // ,date:string
    ): ListViewElementModel[]{
    const result = [];
    for (const model of data) {
      switch (type) {
        case "ENERGY":
          result.push( new ListViewElementModel(
              model.name,
              model.M1.energy.volume_bought_percent,
              model.M2.energy.volume_bought_percent,
              model.M3.energy.volume_bought_percent,
              model.M4.energy.volume_bought_percent,
              model.M5.energy.volume_bought_percent,
              model.M6.energy.volume_bought_percent,
              model.M7.energy.volume_bought_percent,
              model.M8.energy.volume_bought_percent,
              model.M9.energy.volume_bought_percent,
              model.M10.energy.volume_bought_percent,
              model.M11.energy.volume_bought_percent,
              model.M12.energy.volume_bought_percent
          ))
          break;
        case "GAS":
          result.push(new ListViewElementModel(
            model.name,
            model.M1.gas.volume_bought_percent,
            model.M2.gas.volume_bought_percent,
            model.M3.gas.volume_bought_percent,
            model.M4.gas.volume_bought_percent,
            model.M5.gas.volume_bought_percent,
            model.M6.gas.volume_bought_percent,
            model.M7.gas.volume_bought_percent,
            model.M8.gas.volume_bought_percent,
            model.M9.gas.volume_bought_percent,
            model.M10.gas.volume_bought_percent,
            model.M11.gas.volume_bought_percent,
            model.M12.gas.volume_bought_percent
          ))
          break;
        case "GREEN_CERTIFICATE":
          result.push(new ListViewElementModel(
            model.name,
            model.M1.green_certificates.volume_bought_percent,
            model.M2.green_certificates.volume_bought_percent,
            model.M3.green_certificates.volume_bought_percent,
            model.M4.green_certificates.volume_bought_percent,
            model.M5.green_certificates.volume_bought_percent,
            model.M6.green_certificates.volume_bought_percent,
            model.M7.green_certificates.volume_bought_percent,
            model.M8.green_certificates.volume_bought_percent,
            model.M9.green_certificates.volume_bought_percent,
            model.M10.green_certificates.volume_bought_percent,
            model.M11.green_certificates.volume_bought_percent,
            model.M12.green_certificates.volume_bought_percent
          ))
          break;
        default:
          break;
      }
    }
    return result;
  }

  static CreatePurchaseEntryModelFromResponseData(data: any): PurchaseEntryModel[]{
    const result: PurchaseEntryModel[] = [];
    for(const model of data){
      result.push(new PurchaseEntryModel(
        CreateTimeUnit(model.M1),
        CreateTimeUnit(model.M2),
        CreateTimeUnit(model.M3),
        CreateTimeUnit(model.M4),
        CreateTimeUnit(model.M5),
        CreateTimeUnit(model.M6),
        CreateTimeUnit(model.M7),
        CreateTimeUnit(model.M8),
        CreateTimeUnit(model.M9),
        CreateTimeUnit(model.M10),
        CreateTimeUnit(model.M11),
        CreateTimeUnit(model.M12),
        model.date,
        model.name
      ))
    }
    return result;
  }
}
