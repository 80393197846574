


















































































import AbstractForm from "@/components/forms/AbstractForm";
import FormLayout from "@/components/layouts/FormLayout.vue";
import Component from "vue-class-component";
import { required } from "@/context/shared-kernel/validations";
import FinancialInstrumentAutocomplete from "@/components/form-controls/FinancialInstrumentAutocomplete.vue";
import NumberInput from "@/components/form-controls/NumberInput.vue";
import DateInput from "@/components/form-controls/DateInput.vue";
import TimeInput from "@/components/form-controls/TimeInput.vue";
import WalletAutocomplete from "@/components/form-controls/WalletAutocomplete.vue";
import PurchaseGroupAutocomplete from "@/components/form-controls/PurchaseGroupAutocomplete.vue";
import YearInput from "@/components/form-controls/YearInput.vue";
import AddLotFormModel from "@/context/wallets/models/AddLotFormModel";
import TimePeriodTypeAutocomplete from "@/components/form-controls/TimePeriodTypeAutocomplete.vue";
import { WalletApi } from "@/api/WalletApi";
import { ToastOptions } from "vue-toasted";
import { AxiosResponse } from "axios";
import router from "@/router";
import {Watch} from "vue-property-decorator";

@Component({
  components: {
    YearInput,
    WalletAutocomplete,
    TimeInput,
    DateInput,
    NumberInput,
    FinancialInstrumentAutocomplete,
    FormLayout,
    PurchaseGroupAutocomplete,
    TimePeriodTypeAutocomplete
  }
})
export default class AddLotForm extends AbstractForm {
  formType = 0;

  rules = {
    year: [required],
    purchaseGroup: [required],
    wallets: [required],
    financialInstrument: [required],
    size: [required],
    price: [required],
    purchaseDate: [required],
    timeUnit: [required]
  };

  model: AddLotFormModel = this.getFormModel();

  private getFormModel(): AddLotFormModel {
    return {
      year: new Date().getFullYear().toString(),
      purchaseGroup: null,
      wallets: [],
      size: 0,
      price: 0,
      financialInstrument: null,
      purchaseDate: null,
      timeUnit: null
    };
  }

  private async doSubmit() {
    if(!this.valid){
      return
    }
    if(this.formType == 0){
      this.model.wallets = [];
    } else {
      this.model.purchaseGroup = null;
    }
   await WalletApi.createLot(this.model).then(() => {
      this.successToast("Zadanie dodawania transz dodane do kolejki!");
      // TODO Czy to robić?
      router.push({ path: "/wallets/statuses" });
     }, (error) => {
       for (let i = 0; i < error.response.data.length; i++ ) {
         this.errorToast(error.response.data[i]);
       }
     });
  }

  @Watch('model.year')
  onYearChange() {
    this.model.wallets = []
  }

}
