















































import Vue from "vue";
import Component from "vue-class-component";
import { PurchaseGroup } from "@/context/purchase_groups/model/domain";
import { PurchaseGroupApi } from "@/api/PurchaseGroupApi";
@Component({
  components: {}
})
export default class PurchaseGroupListContainer extends Vue {
  headers: Array<Record<string, unknown>> = [
    {
      text: "#",
      value: "id",
      align: "center"
    },
    {
      text: "Nazwa grupy",
      value: "name",
      align: "center"
    },
    {
      text: "Operacje",
      value: "actions",
      align: "center",
      sortable: false
    }
  ];
  items: PurchaseGroup[] = [];

  async mounted() {
    await this.loadData();
  }
  async loadData() {
    this.items = [];
    this.items = await PurchaseGroupApi.findAll();
  }

  editItem(item) {
    this.$router.push({ path: `edit/${item.id}` });
  }
  showItem(item) {
    this.$router.push({ path: `show/${item.id}` });
  }
  async deleteItem(item) {
    await PurchaseGroupApi.delete(item.id);
    await this.loadData();
  }
}
