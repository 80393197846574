










import Vue from "vue";
import Component from "vue-class-component";
import { WalletApi } from "@/api/WalletApi";

@Component({
  components: {}
})
export default class DownloadContainer extends Vue {
  async mounted() {
    await WalletApi.purchaseXls();
  }
}
