










































import Vue from "vue";
import Component from "vue-class-component";
import ClientAutocomplete from "@/components/form-controls/ClientAutocomplete.vue";
import { RecommendationApi } from "@/api/RecommendationApi";
import { RecommendationListModel } from "@/context/recommendations/models/recommendations/RecommendationListModel";
import { AgreementApi } from "@/api/AgreementApi";
import { AgreementListModel } from "@/context/agreements/model/AgreementListModel";

@Component({
  components: { ClientAutocomplete }
})
export default class AgreementListContainer extends Vue {
  headers: Array<Record<string, unknown>> = [
    {
      text: "#",
      value: "id",
      align: "center"
    },
    {
      text: "Klient",
      value: "client",
      align: "center"
    },
    {
      text: "Sprzedawca",
      value: "seller",
      align: "start"
    },
    {
      text: "Wolumen",
      value: "volume",
      align: "start"
    },
    {
      text: "Media",
      value: "media",
      align: "center"
    },
    {
      text: "Operacje",
      value: "actions",
      align: "center",
      sortable: false
    }
  ];
  items: RecommendationListModel[] = [];
  search: string = "";

  mounted() {
    this.loadItems();
  }

  async loadItems() {
    const response = await AgreementApi.list("search=" + this.search);
    const items = response.data;
    for (let i = 0; i < items.length; i++) {
      items[i] = AgreementListModel.deserialize(items[i]);
    }
    this.items = items;
  }

  editItem(item) {
    this.$router.push({ path: `edit/${item.id}` });
  }
}
