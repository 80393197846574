

















import Vue from "vue";
import Component from "vue-class-component";
import AddLotForm from "@/context/wallets/components/AddLotForm.vue";
import router from "@/router";

@Component({
  components: { AddLotForm }
})
export default class FormContainer extends Vue {

  private goToStatuses() {
    router.push({ path: "/wallets/statuses" });
  }

}
